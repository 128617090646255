import find from 'lodash/find';
import remove from 'lodash/remove';
import groupBy from 'lodash/groupBy';
import Papa from 'papaparse';
import { IRtxModelGeometry, IRtxModelVariant, IRtxVariantMeshes } from 'sharedtypes/rtx/rtx';

import { getUrlExtension } from '~/Utils/html';
import { MODEL_EXTENSION } from '~/Scene/Tool/toolLoader';
import { getAllTextureUrlHigh, userDam } from '~/Utils/dam';
import { getRandomString } from '~/Utils/math';

interface IMeshMaterialImport {
    mesh: string
    material: string
}

export interface IVariantImport {
    id: string
    name: string
    url: string
    meshMaterials: IMeshMaterialImport[]
    error: string
}

interface IAdeoCSVLine {
    EAN: string,
    VARIANT_NAME: string,
    MESH_NAME: string,
    MATERIAL_URL: string,
    MASTER_URL: string,
}

const getVariantFromCSVLine = (csv: IAdeoCSVLine[]): IVariantImport => {
    let error = null;
    const firstLine = csv[0];
    let id = firstLine.EAN;
    const url = firstLine.MASTER_URL;
    const variant = firstLine.VARIANT_NAME;
    if (!id) {
        error = 'Missing EAN';
        id = `Random EAN: ${getRandomString()}`;
    }
    if (id.includes('E+')) {
        error = error || 'The EAN seems to be truncated';
    }
    if (!variant) {
        error = error || 'Missing variant name (key "VARIANT_NAME")';
    }
    if (!url) {
        error = error || 'Missing master url (key "MASTER_URL")';
    } else {
        const ext = getUrlExtension(url);
        const isModelExt = MODEL_EXTENSION.includes(ext);
        const isFromDrive = url.includes('export=download&');
        if (!isModelExt && !isFromDrive) error = error || 'Incorrect model url: bad extension (gltf or glb) or incorrect link';
    }
    const meshMaterials = [];
    for (let i = 0; i < csv.length; i++) {
        const line = csv[i];
        const mesh = line.MESH_NAME;
        if (!mesh) error = error || `Missing mesh name line ${i} (key "MESH_NAME")`;
        const material = line.MATERIAL_URL;
        if (!material) error = error || `Missing material url line ${i} (key "MATERIAL_URL")`;
        if (material.includes('drive.google.com')) error = error || 'Material can\'t be stored on google drive.';
        const test = find(meshMaterials, (mM) => mM.mesh === mesh);
        if (test) {
            error = error || `Two materials are setted for mesh ${mesh} line ${i}`;
        } else {
            meshMaterials.push({ mesh, material });
        }
    }
    return {
        id, name: variant, url, meshMaterials, error
    };
};

const getVariantsFromCsvArray = (csvArr: IAdeoCSVLine[]): IVariantImport[] => {
    const groupedByEAN = groupBy(csvArr, (v) => v.EAN);
    const variantsImport = [];
    const EANs = Object.keys(groupedByEAN);
    EANs.forEach((EAN) => {
        const variant = getVariantFromCSVLine(groupedByEAN[EAN]);
        variantsImport.push(variant);
    });
    return variantsImport;
};

export const checkCsvString = (
    csvString: string,
    success: (variantArr: IVariantImport[]) => void,
    error: (error: string) => void,
): void => {
    Papa.parse(csvString, {
        header: true,
        complete(results) {
            const csvArr: IAdeoCSVLine[] = results.data;
            const variantsImport = getVariantsFromCsvArray(csvArr);
            success(variantsImport);
        },
        error(e) {
            error(e.message);
            console.log('Error csv import:', e);
        }
    });
};

export const checkCsvFile = (
    files: File[],
    success: (variantArr: IVariantImport[]) => void,
    error: (error: string) => void,
): void => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
        const text = reader.result as string;
        checkCsvString(text, (varArr) => {
            success(varArr);
        }, error);
    };
    reader.onerror = (e) => {
        console.error(e);
        error(`Error occurred reading file: ${file.name}`);
    };
    reader.readAsText(file);
};

const getVariantByModel = (variantArr: IVariantImport[]): IVariantImport[] => {
    //* Do not use a variant with issues
    remove(variantArr, (v) => v.error);
    return groupBy(variantArr, (v: IVariantImport) => v.url);
};

export const variantArrToVariantRtx = (variantArr: IVariantImport[]): IRtxModelVariant[] => {
    const groupedByModel = getVariantByModel(variantArr);
    let modelVariants: IRtxModelVariant[] = [];
    const urls = Object.keys(groupedByModel);
    urls.forEach((url) => {
        const variantLines: IVariantImport[] = groupedByModel[url];
        const variants = variantLines.map((v: IVariantImport) => {
            const variantMeshesList = v.meshMaterials.map((meshmaterial: IMeshMaterialImport) => {
                const mat = meshmaterial.material;
                const damTextures = getAllTextureUrlHigh(mat);
                const variantMeshes: IRtxVariantMeshes = {
                    meshes: [meshmaterial.mesh],
                    material: {
                        damName: userDam,
                        damMaterial: mat,
                        ...damTextures
                    }
                };
                return variantMeshes;
            });
            return {
                id: v.id, model: v.id, name: v.name, variantMeshesList
            };
        });
        modelVariants = modelVariants.concat(variants);
    });
    return modelVariants;
};

export const variantArrToModelRtx = (variantArr: IVariantImport[]): IRtxModelGeometry[] => {
    const groupedByModel = getVariantByModel(variantArr);
    const urls = Object.keys(groupedByModel);
    const modelsRtx = urls.map((url) => ({
        id: groupedByModel[url][0].id,
        url,
        position: { x: 0, y: 0.5, z: 0 },
        rotation: {
            x: 0, y: 0, z: 0, w: 1
        },
        scale: { x: 1, y: 1, z: 1 }
    }));
    return modelsRtx;
};
