import React, {
    useState, useEffect, FC
} from 'react';
import {
    View, Heading, Flex, Text, Button, ProgressCircle
} from '@adobe/react-spectrum';
import Launch from '@spectrum-icons/workflow/Launch';
import { toast } from 'react-toastify';
import { IRtxImage, IRtxVideo, IRtxModel } from 'sharedtypes/rtx/rtx';
import {
    IScenario, IScenarioImage, IScenarioModel, IScenarioVideo
} from 'sharedtypes/rtx/scenario';

import { colorBase255toBase1 } from '~/Utils/color';
import { useAuthContext } from '~/Context/contextAuth';
import { ScenarioModels } from './scenarioModels';
import { ScenarioList } from '~/Components/Scenario/scenarioList';
import { getTimeStringScenarios } from '~/Services/Rtx/rtxTimeCalculator';
import {
    checkRaytracingOption, IRaytracingEvent, raytracingObservable, startRtxRender
} from '~/Services/Rtx/rtxObservable';
import { getRtxCamera, getRtxCameras, getRtxScenery } from '~/Services/Rtx/rtx';
import { IVariantImport, variantArrToVariantRtx, variantArrToModelRtx } from '~/Services/Scenario/scenarioCsv';
import { DashboardHeader } from '../dashboardHeader';
import { DashboardFooter } from '../dashboardFooter';

export const ScenarioDashboard: FC = () => {
    const { user, isAdmin } = useAuthContext();
    const [renderDescription, setRenderDescription] = useState('');
    const [waitingServer, setWaitingServer] = useState(false);
    const [renderEnabled, setRenderEnabled] = useState(false);
    const [selectedVariants, setSelectedVariants] = useState<IVariantImport[]>([]);
    const [selectedScenarios, setSelectedScenarios] = useState<IScenario[]>([]);

    const updateRenderTime = () => {
        const variantsNumber = selectedVariants.length;
        const scenariosNumber = selectedScenarios.length;
        const enable = (variantsNumber !== 0 && scenariosNumber !== 0);
        setRenderEnabled(enable);
        const timeString = getTimeStringScenarios(variantsNumber, selectedScenarios);
        setRenderDescription(timeString);
    };

    useEffect(() => {
        if (!waitingServer) updateRenderTime();
    }, [selectedVariants, selectedScenarios, waitingServer]);

    const onAdd = ({ message }) => {
        toast.success(`${message} You will receive an email once over.`);
        setWaitingServer(false);
    };

    const onError = (e) => {
        toast.error(`Scenario(s) failed to launched: ${e.error}`);
        setWaitingServer(false);
    };

    useEffect(() => {
        raytracingObservable.on(IRaytracingEvent.Add, onAdd);
        raytracingObservable.on(IRaytracingEvent.Error, onError);
        return function cleanup() {
            raytracingObservable.off(IRaytracingEvent.Add, onAdd);
            raytracingObservable.off(IRaytracingEvent.Error, onError);
        };
    }, []);

    const launchScenarios = () => {
        if (!renderEnabled) return;
        checkRaytracingOption(isAdmin, () => {
            const variants = variantArrToVariantRtx(selectedVariants);
            const models = variantArrToModelRtx(selectedVariants);

            selectedScenarios.forEach(async (scenario) => {
                const { type, environment, state } = scenario;
                const scenery = await getRtxScenery(state, environment);
                const modelVariants = {
                    type,
                    email: user.email,
                    models,
                    variants
                };

                if (type === 'image') {
                    const scenarioImage = state as IScenarioImage;
                    const { resolutions, povs } = scenarioImage;
                    const cameras = await getRtxCameras(povs);
                    const imageRtx: IRtxImage = {
                        ...scenery,
                        ...modelVariants,
                        cameras,
                        sizes: resolutions,
                        format: state.format,
                    };
                    startRtxRender(imageRtx, type);
                } else if (type === 'video') {
                    const scenarioVideo = state as IScenarioVideo;
                    const { resolution, initialCameraGeometry, background_color } = scenarioVideo;
                    const rtxBack = colorBase255toBase1(background_color);
                    const camera = await getRtxCamera(initialCameraGeometry);
                    const videoRtx: IRtxVideo = {
                        ...scenery,
                        ...modelVariants,
                        ...scenarioVideo,
                        camera,
                        background_color: rtxBack,
                        scene_dimensions: resolution,
                    };
                    startRtxRender(videoRtx, type);
                } else if (type === 'model') {
                    const scenarioModel = state as IScenarioModel;
                    const modelRtx: IRtxModel = {
                        ...modelVariants,
                        ...scenarioModel,
                    };
                    startRtxRender(modelRtx, type);
                }
                setRenderEnabled(false);
                setWaitingServer(true);
            });
        });
    };

    return (
        <>
            <DashboardHeader title="Scenarios">
                <Flex direction="column" gap="size-100">
                    <ScenarioModels
                        onSelect={setSelectedVariants}
                    />
                    <Heading level={3} marginBottom="0">Select Scenarios</Heading>
                    <ScenarioList
                        onSelect={setSelectedScenarios}
                        deletable
                    />
                </Flex>
            </DashboardHeader>
            <DashboardFooter>
                <Flex direction="row" gap="size-100" alignItems="center" height="100%">
                    <Text>{renderDescription}</Text>
                    <View flex />
                    <Button
                        variant="cta"
                        width="size-3000"
                        onPress={launchScenarios}
                        isDisabled={!renderEnabled}
                    >
                        {waitingServer
                            ? <ProgressCircle aria-label="Waiting server response" size="S" isIndeterminate />
                            : <Launch />}
                        <Text>{waitingServer ? 'Awaiting confirmation' : 'Render Scenario(s)'}</Text>
                    </Button>
                </Flex>
            </DashboardFooter>
        </>
    );
};
